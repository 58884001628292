<template>
<div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
            <div class="bg-soft-primary">
                <div class="row bg-soft-primary">
                    <div class="col-7">
                        <div class="text-primary p-4">
                            <h5 class="text-primary">Vui lòng đăng nhập</h5>
                        </div>
                    </div>
                    <div class="col-5 align-self-end">
                        <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
                    </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <b-form class="p-2" @submit.prevent="handleSubmit">
                    <div id="alert" v-if="isShow" class="alert alert-danger" display="none" role="alert">
                        {{statusMessage}}
                    </div>
                    <b-form-group id="input-group-1" label="Tên đăng nhập" label-for="input-1">
                        <b-form-input id="input-1" v-model="username" type="text" placeholder="Tên đăng nhập">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="Mật khẩu" label-for="input-2">
                        <b-form-input id="input-2" v-model="password" type="password" placeholder="Nhập mật khẩu"></b-form-input>
                    </b-form-group>
                    <div class="mt-3">
                        <b-button type="submit" variant="primary" class="btn-block">Đăng nhập</b-button>
                    </div>
                    <div class="mt-3 text-right">
                        <a href="javascript:void(0)" class="btn-block" @click="gotoForgetPass">Quên mật khẩu</a>
                    </div>
                </b-form>
            </div>
            <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
            <p>
                © Bản quyền thuộc Hợp tác xã Nông nghiệp số
            </p>
        </div>
        <!-- end row -->
    </div>
    <!-- end col -->
</div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import {
    router
} from "/src/router"

export default {
    page: {
        title: "Login",
        meta: [{
            name: "description",
            content: "Đăng nhập"
        }]
    },
    name: 'Login',
    data() {
        return {
            username: '',
            password: '',
            submitted: false,
        }        
    },
    computed: {
        ...mapState('account', ['status']),
        statusMessage() {
            return this.status.message
        },
        isShow() {
            return this.status.message !== "" && this.status.loggedIn === false;
        }
    },
    methods: {
        ...mapActions('account', ['login', 'logout']),
        handleSubmit() {
            this.submitted = true;
            let {
                username,
                password
            } = this;
            if (username || password) {
                this.setPassBase64(password)
                this.login({
                    username,
                    password
                })
            }
        },
        gotoForgetPass() {
            router.push('/auth/phone', () => {})
        },

        setPassBase64(pass) {
            localStorage.setItem('pass',pass)
        }
    }
}
</script>

<style lang="scss" module>

</style>
